// useLang.js
import {computed} from 'vue';
import {onClickOutside} from "@vueuse/core";
import {getActiveLanguage} from "laravel-vue-i18n";
import {usePage} from "@inertiajs/vue3";
import {__, useRoute} from "./utils.js";

export function useLang(dropdownRef = null) {
    // If dropdownRef is provided, set up click outside handler
    if (dropdownRef) {
        onClickOutside(dropdownRef, () => dropdownRef.value.open = false);
    }

    const page = usePage();

    const langName = (locale) => {
        switch (locale) {
            case "et":
                return __("Eesti");
            case "ru":
                return __("Русский");
            case "fi":
                return __("Suomi");
            default:
                return locale;
        }
    };

    const activeLang = computed(() => getActiveLanguage());
    const locales = computed(() => page.props.locales);

    const changeLang = (lang) => {
        let location;
        const currentRoute = useRoute();
        const currentRouteName = currentRoute.current();
        const add = "-" + lang;
        if (activeLang.value === lang) {
            return currentRoute;
        }
        switch (currentRouteName) {
            case "news.show-et":
            case "news.show-ru": {
                const newsItem = page.props.newsItem;
                if (newsItem.alias) {
                    location = useRoute("news.show" + add, {news: newsItem.alias});
                } else {
                    location = useRoute("news" + add);
                }
                break;
            }
            case "page-et":
            case "page-ru": {
                const currentPage = page.props.page;
                if (currentPage.alias) {
                    location = useRoute("page" + add, {page: currentPage.alias});
                } else {
                    location = useRoute("home" + add);
                }
                break;
            }
            case "category-et":
                location = useRoute("category-ru", {
                    ...currentRoute.params,
                    category: page.props.category.url_ru
                });
                break;
            case "category-ru":
                location = useRoute("category-et", {
                    ...currentRoute.params,
                    category: page.props.category.url
                });
                break;
            case "product-et":
                location = useRoute("product-ru", {
                    ...currentRoute.params,
                    product: page.props.product.url_ru
                });
                break;
            case "product-ru":
                location = useRoute("product-et", {
                    ...currentRoute.params,
                    product: page.props.product.url
                });
                break;
            default: {
                const routeName =
                    activeLang.value === "et"
                        ? currentRouteName.substring(0, currentRouteName.lastIndexOf("-et"))
                        : currentRouteName.substring(0, currentRouteName.lastIndexOf("-ru"));
                location = useRoute(routeName + add, {...currentRoute.params});
            }
        }
        window.location = location;
    };

    return {
        langName,
        activeLang,
        locales,
        changeLang
    };
}