<template>
  <li class="flex-row">
    <a
        v-for="locale in locales"
        :key="locale"
        class="inline-block w-fit"
        :class="{'font-bold': activeLang === locale}"
        :href="useRoute('home-' + locale)"
        @click.prevent="changeLang(locale)"
    >
      {{ langName(locale) }}&nbsp;
      <span
          class="!w-5 h-5 fi fis rounded-full border"
          :class="{
          'fi-ee': locale === 'et',
          'fi-ru': locale === 'ru',
          'fi-fi': locale === 'fi'
        }"></span>
    </a>
  </li>
</template>

<script setup>
import {ref} from "vue";
import {useRoute} from "../utils.js";
import {useLang} from "../useLang.js";

const target = ref(null);
const {langName, activeLang, locales, changeLang} = useLang(target);
</script>
