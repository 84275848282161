<template>
  <details ref="target" class="dropdown">
    <summary class="inline-block pr-2">
      <span class="flex w-7 h-7 p-1 items-center justify-center rounded-full border border-black mx-2 -mt-5">
        <span
            class="!w-5 h-5 fi fis rounded-full"
            :class="{
          'fi-ee': activeLang === 'et',
          'fi-ru': activeLang === 'ru',
          'fi-fi': activeLang === 'fi'
        }"></span>
      </span>
    </summary>
    <ul
        class="p-2 shadow-lg border border-gray-300 -top-4 right-1.5 menu dropdown-content z-[1] bg-base-100 rounded-2xl w-32">
      <li v-for="locale in locales" :key="locale">
        <a class="block px-2" :href="useRoute('home-' + locale)" @click.prevent="changeLang(locale)">
          {{ langName(locale) }}
          <span
              class="float-end !w-5 h-5 fi fis rounded-full"
              :class="{
          'fi-ee': locale === 'et',
          'fi-ru': locale === 'ru',
          'fi-fi': locale === 'fi'
        }"></span>
        </a>
      </li>
    </ul>
  </details>
</template>

<script setup>
import {ref} from "vue";
import {useRoute} from "../utils.js";
import {useLang} from "../useLang.js";

const target = ref(null);
const { langName, activeLang, locales, changeLang } = useLang(target);
</script>
