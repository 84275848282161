<template>
  <div
      class="dropdown menu-item static cursor-pointer d hover:text-secondary-500 border-b-white hover:border-b-secondary-500 border-b-2"
      :class="'c-' + item.id"
      @mouseover="showMenu"
      @mouseleave="hideMenuTimeout"
  >
    <span class="block h-full">{{ item ? trField(item, "title") : "" }}</span>
    <div
        class="!visible !opacity-100 container max-w-screen-1.5xl mx-auto left-0 right-0 shadow-xl absolute p-7 gap-7 z-50 mt-[3px] w-full origin-top-right bg-white focus:outline-none grid grid-cols-4"
        :class="{'block': visible, 'hidden': !visible }"
    >
      <div
          v-for="(item2, idx2) in item.children"
          :key="idx2"
          class="pb-7">
        <Link
            :class="'tp-' + item2.id"
            class="block text-primary-500 hover:text-secondary-500 focus:text-secondary-500"
            :href="trRoute('category', { category: trField(item2, 'url') })"
            @click="hideMenu"
        >
          {{ trField(item2, "title") }}
        </Link>
        <template v-for="(lnk, name, index) in trField(item2, 'links')" :key="lnk">
          <Link
              class="whitespace-nowrap text-primary-500 font-normal hover:text-secondary-500 focus:text-secondary-500"
              :href="lnk"
              @click="hideMenu"
          >
            &nbsp;{{ name }}&nbsp;
          </Link>
          <span
              v-if="index < Object.keys(trField(item2, 'links')).length - 1"
              class="text-gray-300"> | </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import {Link} from "@inertiajs/vue3";
import {trField, trRoute} from "../utils";
import {ref} from "vue";

defineProps({
  item: Object
});
let timer;
const visible = ref(false);
const hideMenu = () => visible.value = false;
const showMenu = () => {
  window.clearTimeout(timer);
  visible.value = true;
};
const hideMenuTimeout = () => {
  timer = setTimeout(() => hideMenu(), 500);
};
</script>